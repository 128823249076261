import { useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { feedback } from '../../../core/feedback'
import { useJCACalls } from './jca-calls.hook'
import { jcaReducer } from '../reducers'
import { JCA_INITIAL_STATE, JCA_ACTIONS_TYPES } from '../reducers'
import {
  JCA_VALIDATED_STATE,
  JCA_LOADED_ON_ZEUS_STATE,
  JCA_VALIDATED_DELIVERY_FAILED_STATE,
  JCA_LOADED_ON_SAP_STATE,
} from '../components/form/jca-form.constants'

export const useJCAReducer = () => {
  const [state, dispatch] = useReducer(jcaReducer, JCA_INITIAL_STATE)
  const history = useHistory()
  const { getJJCAACall, getJCACall, putJCAStateCall, putRejectJCACall } = useJCACalls()
  const { putResolveIssueCall, putSendToZeusCall, getJCAEventsCall } = useJCACalls()

  const redirectToTable = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/jca' + prevSearch)
  }

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: JCA_ACTIONS_TYPES.SET_LOADING,
      })
      getJJCAACall(config)
        .then(({ data }) => {
          dispatch({
            type: JCA_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['registros'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_registros: data['total_registros'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: JCA_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getJCA = useCallback(
    (id) => {
      new Promise((resolve, reject) => {
        dispatch({
          type: JCA_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        getJCACall(id)
          .then(({ data }) => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA,
              payload: {
                ...data,
                cif_empresa: data.empresa.cif,
                motivo: data.motivo?.toString() || '',
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validateJCA = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        putJCAStateCall(id, JCA_VALIDATED_STATE)
          .then(({ data }) => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA,
              payload: {
                ...data,
                cif_empresa: data.empresa.cif,
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rejectJCA = useCallback(
    (id, rejectReason) => {
      return new Promise((resolve, reject) => {
        putRejectJCACall(id, { observaciones_rechazo: rejectReason })
          .then(({ data }) => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA,
              payload: {
                ...data,
                cif_empresa: data.empresa.cif,
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const resolveIssue = useCallback(
    (id, formState) => {
      return new Promise((resolve, reject) => {
        putResolveIssueCall(id, formState)
          .then(() => resolve({ redirectToTable }))
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const loadOnZeusJCA = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        putJCAStateCall(id, JCA_LOADED_ON_ZEUS_STATE)
          .then(({ data }) => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA,
              payload: {
                ...data,
                cif_empresa: data.empresa?.cif || '',
              },
            })
            resolve({ redirectToTable })
          })
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendToZeusJCA = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        putSendToZeusCall(id)
          .then(({ data }) => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA,
              payload: {
                ...data,
                cif_empresa: data.empresa?.cif || '',
              },
            })
            if (data.estado === JCA_VALIDATED_DELIVERY_FAILED_STATE) {
              feedback('info', 'Se ha vuelto a enviar a Zeus pero el envío ha fallado de nuevo')
            }
            resolve({ redirectToTable })
          })
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const loadOnSapJCA = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        putJCAStateCall(id, JCA_LOADED_ON_SAP_STATE)
          .then(({ data }) => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA,
              payload: {
                ...data,
                cif_empresa: data.empresa?.cif || '',
              },
            })
            resolve({ redirectToTable })
          })
          .catch(() => {
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getJCAEvents = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getJCAEventsCall(id)
          .then(({ data }) => {
            const formattedData = data
              .map((record) => ({
                ...record,
                responsable: `${record.gestor || record.instalador || '--'} ${
                  record.gestor ? '(Gestor)' : record.instalador ? '(Instalador)' : ''
                }`,
                mensaje: `${record.mensaje} ${record.descripcion ? `(${record.descripcion})` : ''}`,
              }))
              .sort((a, b) => new Date(b.dg_ts_insert) - new Date(a.dg_ts_insert))
            dispatch({
              type: JCA_ACTIONS_TYPES.SET_JCA_EVENTS,
              payload: formattedData,
            })
          })
          .then(() => resolve())
          .catch((error) => reject(error))
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: JCA_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = (id) => {
    const props = {
      pathname: `/jca/${id}`,
      state: { prevSearch: history.location.search },
    }
    return history.push(props)
  }

  return {
    setSelectedTableRows,
    onClickTableRow,
    getTableData,
    getJCA,
    validateJCA,
    rejectJCA,
    resolveIssue,
    sendToZeusJCA,
    loadOnZeusJCA,
    loadOnSapJCA,
    getJCAEvents,
    ...state,
  }
}
