import packageJson from '../../../../../package.json'

const { REACT_APP_ENVIRONMENT } = process.env

const DOCUMENTS_URL = 'https://www.nedgia.es/colaboradores/documentos-a-presentar/'

const EXISTING_CONNECTIONS_REQUEST_URL = 'https://www.nedgia.es/conexiones-y-actuaciones-en-la-red/'
// 'https://www.nedgia.es/clientes/servicios-de-gas-natural/hogar/actuaciones-en-acometidas-existentes/'

export const MENU_ITEMS = {
  CALL_CENTER: [
    {
      label: 'Consulta de potenciales',
      path: '/consult',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
    {
      label: 'Tarifas',
      path: '/tariffs',
    },
    {
      label: 'Gestión de colaboradores',
      children: [
        {
          label: 'Empresas instaladoras',
          path: '/eeii',
        },
        {
          label: 'Empresas IP',
          path: '/eeii-ip',
        },
        {
          label: 'Asociaciones/Gremios',
          path: '/guilds',
        },
        {
          label: 'Asociaciones/Gremios IP',
          path: '/guilds-ip3',
        },
      ],
    },
    {
      label: 'Seguimiento IP3',
      children: [
        {
          label: 'Seguimiento IP',
          path: '/ip',
        },
        {
          label: 'Seguimiento JCA',
          path: '/jca',
        },
      ],
    },
    {
      label: 'Accesos Interés',
      children: [
        {
          label: 'Documentación captación',
          url: DOCUMENTS_URL,
        },
        {
          label: 'Solicitud actuación en acometidas existentes',
          url: EXISTING_CONNECTIONS_REQUEST_URL,
        },
        {
          label: 'Ofertas',
          path: '/offers',
        },
        {
          label: 'Manuales y Guías',
          path: '/private-area-navigation-guide',
        },
      ],
    },
  ],
  SELECTRA: [
    {
      label: 'Consulta de potenciales',
      path: '/consult',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
  ],
  GSS_VT: [
    {
      label: 'Consulta de potenciales',
      path: '/consult',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
  ],
  ACCOM: [
    {
      label: 'Consulta de potenciales',
      path: '/consult',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
  ],
  SOPORTE_PROCESOS: [
    {
      label: 'Soporte de procesos',
      path: '/supervision',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
    {
      label: 'Gestión de colaboradores',
      children: [
        {
          label: 'Empresas instaladoras',
          path: '/eeii',
        },
        {
          label: 'Asociaciones/Gremios',
          path: '/guilds',
        },
      ],
    },
  ],
  SIR: [
    {
      label: 'Solicitudes',
      path: '/sir',
    },
  ],
  DELEGADO: [
    {
      label: 'Panel de control',
      path: '/control-panel',
    },
    {
      label: 'Mis solicitudes',
      path: '/my-applications',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
    {
      label: 'Consulta de potenciales',
      path: '/consult',
    },
  ],
  GESTOR_GC: [
    {
      label: 'Consulta de potenciales',
      path: '/consult',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
  ],
  GESTOR: [
    {
      label: 'Panel de control',
      path: '/control-panel',
    },
    {
      label: 'Mis solicitudes',
      path: '/my-applications',
    },
    {
      label: 'Gestión de solicitudes',
      path: '/applications',
    },
    {
      label: 'Consulta de potenciales',
      path: '/consult',
    },
  ],
  GESTOR_IP: [
    {
      label: 'Panel de control',
      path: '/control-panel',
    },
    {
      label: 'Empresas IP',
      path: '/eeii-ip',
    },
    {
      label: 'Asociaciones/Gremios IP',
      path: '/guilds-ip3',
    },
    {
      label: 'Seguimiento IP',
      path: '/ip',
    },
    {
      label: 'Seguimiento JCA',
      path: '/jca',
    },
    {
      label: 'Histórico IP3',
      children: [
        {
          label: 'IP',
          path: '/ip3-historical/ip',
        },
        {
          label: 'JCA',
          path: '/ip3-historical/jca',
        },
      ],
    },
  ],
  E_COMMERCE: [
    {
      label: 'Solicitudes',
      path: '/applications',
    },
    {
      label: 'Instaladores',
      path: '/eeii',
    },
    {
      label: 'Asociaciones',
      path: '/guilds',
    },
    {
      label: 'Tarifas',
      path: '/tariffs',
    },
  ],
  DEFAULT: [],
}

export const MENU_ITEMS_E_COMMERCE = [
  {
    label: 'Solicitudes',
    path: '/applications',
  },
]

export const DEFAULT_ROUTE = '/applications'

export const PROFILE_ROUTE = '/profile'

export const SETTINGS_ROUTE = '/settings'

export const APP_VERSION_MSSG = `Version ${packageJson.version} (${REACT_APP_ENVIRONMENT})`
