import { TextInput, DateInput, SelectInput, CheckListInput } from '../../../../ui'
import { FileUploaderInput } from '../../../../ui'
import { JCAAddressesViewer } from './jca-addresses-viewer.component'

const GRID_ITEM_FULL = {
  item: true,
  xs: 12,
  sm: 12,
  md: 12,
}

const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 6,
  md: 6,
}

export const INPUTS_CONFIG = ({ data, combos, intl, onChange, readOnly }) => {
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: 'pages.jca.detail.fields.' + extension + '.title' })
  const DEFAULT_PROPS = { onChange, readOnly, required: true }
  return {
    TIPO_INSTALACION: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      value: data['tipo_instalacion'],
      values: combos.tipo_instalacion?.data || [],
      title: txt('tipo_instalacion'),
      name: 'tipo_instalacion',
    },
    UNE: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      values: combos.une?.data || [],
      value: data['une'],
      title: txt('une'),
      name: 'une',
    },
    TIPO_GAS: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['tipo_gas'],
      title: txt('tipo_gas'),
      name: 'tipo_gas',
    },
    CIF_EMPRESA: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['cif_empresa'],
      title: txt('cif_empresa'),
      name: 'cif_empresa',
    },
    ESTADO: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      values: combos.estados_jca?.data || [],
      value: data['estado'],
      title: txt('estado'),
      name: 'estado',
    },
    OBSERVACIONES_RECHAZO: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['observaciones_rechazo'],
      title: txt('observaciones_rechazo'),
      name: 'observaciones_rechazo',
      hidden: !(data['observaciones_rechazo']?.length && JCA_REJECTED_STATES[data['estado']]),
    },
    SITUACION_SUMINISTRO: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['situacion_suministro'],
      values: combos.situacion_suministro?.data || [],
      title: txt('situacion_suministro'),
      name: 'situacion_suministro',
    },
    FECHA_JCA: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_jca'],
      title: txt('fecha_jca'),
      name: 'fecha_jca',
    },
    FECHA_CREACION: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_creacion'],
      title: txt('fecha_creacion'),
      name: 'fecha_creacion',
    },
    ANOMALIAS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      values: data.anomalias?.map((a) => ({ key: a.id, value: a.literal })) || [],
      value: data.anomalias?.map((a) => ({ key: a.id, value: a.literal })) || [],
      comboId: 'anomalias',
      title: txt('anomalias'),
      name: 'anomalias',
    },
    JUSTIFICANTE: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('justificante_jca'),
      name: 'justificante_jca',
      currentDoc: data['justificante_jca'],
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
    },
    DIRECCIONES: {
      component: JCAAddressesViewer,
      addresses: data['direcciones'] || [],
      instType: data['tipo_instalacion'],
    },
  }
}

export const FORM_CONFIG = ({ intl }) => [
  {
    fields: [
      { ref: 'TIPO_INSTALACION', conf: GRID_ITEM_LARGE },
      { ref: 'UNE', conf: GRID_ITEM_LARGE },
      { ref: 'TIPO_GAS', conf: GRID_ITEM_LARGE },
      { ref: 'ESTADO', conf: GRID_ITEM_LARGE },
      { ref: 'OBSERVACIONES_RECHAZO', conf: GRID_ITEM_LARGE },
      { ref: 'FECHA_CREACION', conf: GRID_ITEM_LARGE },
      { ref: 'CIF_EMPRESA', conf: GRID_ITEM_LARGE },
    ],
    divider: true,
  },
  {
    title: intl.formatMessage({ id: 'pages.jca.detail.gridForm.title.2' }),
    fields: [
      { ref: 'DIRECCIONES', conf: GRID_ITEM_FULL },
      { ref: 'FECHA_JCA', conf: GRID_ITEM_LARGE },
      { ref: 'JUSTIFICANTE', conf: GRID_ITEM_FULL },
    ],
  },
]

export const NONCONFOMITY_FIELDS = (fm) => [
  {
    key: 'motivo',
    label: fm({ id: 'pages.jca.detail.fields.motivo.title' }),
    type: 'combo',
    comboId: 'motivos_no_conformidad_jca',
  },
  {
    key: 'observaciones',
    label: fm({ id: 'pages.jca.detail.fields.observaciones.title' }),
  },
  {
    key: 'resultado_incidencia',
    label: fm({ id: 'pages.jca.detail.fields.resultado_incidencia.title' }),
    type: 'combo',
    comboId: 'resultado_no_conformidad',
  },
  {
    key: 'observaciones_incidencia_gestor',
    label: fm({ id: 'pages.jca.detail.fields.observaciones_incidencia_gestor.title' }),
  },
]

export const INSPECTION_FIELDS = (fm) => [
  {
    key: 'tipo_instalacion',
    label: fm({ id: 'pages.jca.detail.fields.tipo_instalacion.title' }),
  },
  {
    key: 'une',
    label: fm({ id: 'pages.jca.detail.fields.une.title' }),
  },
]

export const JCA_VALIDATED_STATE = '40'
export const JCA_VALIDATED_DELIVERY_FAILED_STATE = '42'
export const JCA_NONCONFORMITY_STATE = '15'
export const JCA_LOADED_ON_ZEUS_STATE = '41'
export const JCA_LOADED_ON_SAP_STATE = '50'

export const JCA_CAN_VALIDATE_STATES = {
  30: true,
}

export const JCA_CAN_REJECT_STATES = {
  30: true,
  40: true,
  42: true,
}

export const JCA_CAN_SEND_TO_ZEUS_STATES = {
  42: true,
}

export const JCA_NONCONFORMITY_STATES = {
  15: true,
  39: true,
}

export const JCA_REJECTED_STATES = {
  25: true,
}

export const JCA_CAN_SEND_TO_ZEUS_MANUALLY_STATES = {
  40: true,
}
