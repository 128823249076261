import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import { ThemeButton, useModalContext, ConfirmationDialog } from '../../../../ui'
import { useIPContext } from '../../contexts'
import { IP_CAN_VALIDATE_STATES, IP_NONCONFORMITY_STATES } from './ip-form.constants'
import { IP_CAN_REJECT_STATES, IP_CAN_SEND_TO_ZEUS_STATES } from './ip-form.constants'
import { IP_CAN_SEND_TO_ZEUS_MANUALLY_STATES } from './ip-form.constants'
import { ResolveIssueDialog, RejectInspDialog } from '../dialogs'

export const IPStateBtns = () => {
  const { formatMessage } = useIntl()
  const { ip, validateIP, rejectIP, resolveIssue, sendToZeusIP } = useIPContext()
  const { loadOnZeusIP, loadOnSapIP } = useIPContext()
  const {
    actions: { open },
  } = useModalContext()
  const permissions = useSelector((state) => state.global.permissions)
  const ipOrigen = ip?.origen || 'zeus'

  const handleValidateIP = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.ip.detail.validateDialog.title' }),
        text: formatMessage({ id: 'pages.ip.detail.validateDialog.description' }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: () => validateIP(ip.id),
      },
      type: 'centered',
    })
  }

  const handleLoadOnIP = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({
          id:
            ipOrigen === 'zeus'
              ? 'pages.ip.detail.loadedOnZeusBtn.title'
              : 'pages.ip.detail.loadedOnSapBtn.title',
        }),
        text: formatMessage({
          id:
            ipOrigen === 'zeus'
              ? 'pages.ip.detail.loadedOnZeusBtn.description'
              : 'pages.ip.detail.loadedOnSapBtn.description',
        }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: () => (ipOrigen === 'zeus' ? loadOnZeusIP(ip.id) : loadOnSapIP(ip.id)),
      },
      type: 'centered',
    })
  }

  const handleSendToZeusIP = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.ip.detail.sendToZeusBtn.title' }),
        text: formatMessage({ id: 'pages.ip.detail.sendToZeusBtn.description' }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: () => sendToZeusIP(ip.id),
      },
      type: 'centered',
    })
  }

  const handleRejectIP = () => {
    open({
      Component: RejectInspDialog,
      data: { ip, rejectIP },
      type: 'centered',
    })
  }

  const handleResolveIssue = () => {
    open({
      Component: ResolveIssueDialog,
      data: { ipId: ip.id, resolveIssue },
      type: 'centered',
    })
  }

  const SendToButton = () => {
    if (!permissions.validar_ip || ipOrigen === 'sap') return null

    if (IP_CAN_SEND_TO_ZEUS_STATES[ip.estado]) {
      return (
        <Box mr={1}>
          <ThemeButton color="secondary" onClick={handleSendToZeusIP}>
            {formatMessage({ id: 'pages.ip.detail.sendToZeusBtn.label' })}
          </ThemeButton>
        </Box>
      )
    }

    return null
  }

  const LoadedOnButton = () => {
    if (!permissions.validar_ip) return null

    if (IP_CAN_SEND_TO_ZEUS_STATES[ip.estado] || IP_CAN_SEND_TO_ZEUS_MANUALLY_STATES[ip.estado]) {
      return (
        <Box mr={1}>
          <ThemeButton color="default" onClick={handleLoadOnIP}>
            {formatMessage({
              id:
                ipOrigen === 'zeus'
                  ? 'pages.ip.detail.loadedOnZeusBtn.label'
                  : 'pages.ip.detail.loadedOnSapBtn.label',
            })}
          </ThemeButton>
        </Box>
      )
    }

    return null
  }

  return (
    <Box display="flex">
      {permissions.validar_ip && IP_NONCONFORMITY_STATES[ip.estado] ? (
        <Box mr={1}>
          <ThemeButton color="secondary" onClick={handleResolveIssue}>
            {formatMessage({ id: 'pages.ip.detail.resolveIssueBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
      {permissions.validar_ip && IP_CAN_VALIDATE_STATES[ip.estado] ? (
        <Box mr={1}>
          <ThemeButton color="secondary" onClick={handleValidateIP}>
            {formatMessage({ id: 'pages.ip.detail.validateBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
      <SendToButton />
      <LoadedOnButton />
      {permissions.rechazar_ip && IP_CAN_REJECT_STATES[ip.estado] ? (
        <Box mr={1}>
          <ThemeButton variant="outlined" onClick={handleRejectIP}>
            {formatMessage({ id: 'pages.ip.detail.rejectBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
    </Box>
  )
}
