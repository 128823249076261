import React, { useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { DeleteOutline } from '@material-ui/icons'
import MUIRichTextEditor from 'mui-rte'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ClearIcon from '@material-ui/icons/Clear'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import { stateToHTML } from 'draft-js-export-html'
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw } from 'draft-js'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import feedback from '../../../core/feedback'
import { NOTICES_EDIT_CCAA_PROFILES } from '../constants/notices-edit.constants'
import { useIntl } from 'react-intl'
import { getISOStringInitDate } from '../../../core/utils'
import esLocale from 'date-fns/locale/es'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const dialogStyles = () => ({
  root: {
    margin: 0,
  },
  dialogPaper: {
    maxWidth: '100%',
  },
  menuPaper: {
    maxHeight: 'calc(50% - 96px)',
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, users_reached = '', created_at = null, ...other } = props
  const intl = useIntl()
  const { formatMessage } = intl
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
        <div>
          <Typography variant="h6" align="center" color="primary" style={{ marginTop: 8 }}>
            <i>{children}</i>
          </Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
        {users_reached ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 10,
            }}
          >
            <Tooltip title={formatMessage({ id: 'pages.notices.popup.edit.users.tooltip' })}>
              <Typography
                variant="body1"
                color="textSecondary"
                display="inline"
                style={{ marginLeft: '10px' }}
              >
                {users_reached}
              </Typography>
            </Tooltip>
            <Typography
              variant="body1"
              color="textSecondary"
              display="inline"
              style={{ paddingRight: '20px' }}
            >
              {formatMessage({ id: 'pages.notices.popup.created.title' })}{' '}
              {new Date(created_at).toLocaleString().substring(0, 10)}
            </Typography>
          </div>
        ) : null}
      </div>
    </MuiDialogTitle>
  )
})

let enddate = new Date()
enddate.setMonth(enddate.getMonth() + 1)

export const PopupEditDialogComponent = withStyles(dialogStyles)(
  ({
    children,
    classes,
    id = '',
    title = '',
    text = '',
    links = [],
    tags = [],
    onRead = () => {},
    onClose = () => {},
    onEdit = () => {},
    open = false,
    tagsList = [],
    profilesSelected = [],
    profilesList = [],
    onDelete = () => {},
    startDate = new Date(),
    endDate = enddate,
    created_at = new Date(),
    users_reached = null,
    ccaa = [],
    ccaaList = [],
    ignore = false,
  }) => {
    const [titleValue, setTitleValue] = useState(title)
    const [textValue, setTextValue] = useState(text)
    const [linksValue, setLinksValue] = useState(links)
    const [tagsValue, setTagsValue] = useState(tags)
    const textEditorRef = useRef()
    const [startDateValue, setStartDateValue] = useState(startDate)
    const [profilesSelectedValue, setProfilesSelectedValue] = useState(profilesSelected)
    const [endDateValue, setEndDateValue] = useState(endDate)
    const [ccaaValue, setCcaaValue] = useState(ccaa)
    const [showCCAA, setShowCCAA] = useState(false)
    const [charCounts, setCharCounts] = useState(
      linksValue.reduce((acc, link, index) => {
        acc[index] = link.value.length || 0
        return acc
      }, {})
    )
    const [errorMessages, setErrorMessages] = useState({})
    const [tagsListDropdown, setTagsListDropdown] = useState(tagsList)

    const intl = useIntl()
    const { formatMessage } = intl

    useEffect(() => {
      setTitleValue(title)
      setTextValue(text)
      setLinksValue(links)
      setTagsValue(tags)
      setStartDateValue(startDate)
      setProfilesSelectedValue(profilesSelected)
      setEndDateValue(endDate)
      setCcaaValue(intArrayToStringArray(ccaa))
      ccaaInputNeeded(profilesSelected)

      setTagsListDropdown(tagsList.map((tag) => tag.value))
    }, [id, open])

    function stringArrayToIntArray(array) {
      return array.map((item) => parseInt(item))
    }

    function intArrayToStringArray(array) {
      return array.map((item) => item.toString())
    }

    function removeLink(del_val) {
      return function () {
        setLinksValue(linksValue.filter((value) => value !== del_val))
      }
    }

    function addLink() {
      return function () {
        if (linksValue.length < 10) setLinksValue(linksValue.concat([{ key: '', value: '' }]))
      }
    }

    function changeTitle(event) {
      setTitleValue(event.target.value)
    }

    const handleUsersChange = (event) => {
      const { value } = event.target
      const new_value = typeof value === 'string' ? value.split(',') : value
      setProfilesSelectedValue(new_value)
      ccaaInputNeeded(new_value)
    }

    const handleccaaChange = (event) => {
      const { value } = event.target
      setCcaaValue(typeof value === 'string' ? value.split(',') : value)
    }

    function formatTextInput(text) {
      const blocksFromHTML = convertFromHTML(text)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      return JSON.stringify(convertToRaw(state))
    }

    function getProfileText(value) {
      if (profilesList) {
        const elem = profilesList.find((profile) => profile.key === value)
        if (elem) return elem.value
      }
      return ''
    }

    function getCCAAText(key) {
      if (ccaaList) {
        const elem = ccaaList.find((ccaa) => ccaa.key === key)
        if (elem) return elem.value
      }
    }

    function deleteNotice() {
      if (window.confirm(formatMessage({ id: 'pages.notices.popup.edit.delete.warning' }))) {
        onDelete()
      }
    }

    function validateData() {
      let valid = true
      if (titleValue === '') {
        valid = false
      }
      if (textValue === '') {
        valid = false
      }
      if (linksValue.length > 0) {
        linksValue.forEach((link) => {
          if (link.key === '' || link.value === '') {
            valid = false
          }
        })
      }
      if (profilesSelectedValue.length === 0) {
        valid = false
      }
      if (showCCAA && ccaaValue.length === 0) {
        valid = false
      }
      if (!startDateValue || !endDateValue) {
        valid = false
      }

      if (tagsValue.length > 10) {
        valid = false
      }

      return valid
    }

    function save() {
      if (validateData()) {
        const payload = {
          title: titleValue,
          body: textValue,
          links: linksValue,
          tags: tagsValue,
          target_users: profilesSelectedValue,
          start_date: startDateValue,
          end_date: endDateValue,
          ccaa: stringArrayToIntArray(ccaaValue),
        }
        onEdit(payload)
      } else {
        feedback('error', formatMessage({ id: 'calls.notices.form.error.description' }))
      }
    }

    function ccaaInputNeeded(profilesSelected) {
      let ccaaNeeded = false
      if (profilesSelected.length > 0) {
        profilesSelected.forEach((profile) => {
          if (NOTICES_EDIT_CCAA_PROFILES.includes(profile)) {
            ccaaNeeded = true
          }
        })
      }
      setShowCCAA(ccaaNeeded)
    }

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle onClose={onClose} users_reached={users_reached} created_at={created_at}>
          {title == ''
            ? formatMessage({ id: 'pages.notices.filters.header.create.button' })
            : formatMessage({ id: 'pages.notices.filters.header.edit.button' })}
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '15px' }}>
            <TextField
              id="outlined-basic"
              label={formatMessage({ id: 'pages.notices.popup.edit.title.title' })}
              variant="outlined"
              value={titleValue}
              onChange={changeTitle}
              inputProps={{ maxLength: 255 }}
              fullWidth
              error={titleValue == ''}
            />
            <MUIRichTextEditor
              label={formatMessage({ id: 'pages.notices.popup.edit.description.title' })}
              error={textValue == ''}
              controls={[
                'undo',
                'redo',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'numberList',
                'bulletList',
              ]}
              defaultValue={formatTextInput(textValue)}
              onSave={(value) => {
                setTextValue(stateToHTML(convertFromRaw(JSON.parse(value))))
              }}
              onBlur={() => textEditorRef.current.save()}
              ref={textEditorRef}
              maxLength={1000}
            />
            <div>
              <div style={{ display: 'flex', width: '100%', gap: '5px', alignItems: 'center' }}>
                <h5>{formatMessage({ id: 'pages.notices.popup.edit.links.title' })}</h5>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={addLink()}
                  style={{ marginBottom: '0', marginLeft: '1rem' }}
                >
                  {formatMessage({ id: 'pages.notices.popup.edit.links.add' })}
                </Button>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                {linksValue.map((link, index) => (
                  <div
                    style={{ display: 'flex', width: '100%', gap: '5px', alignItems: 'center' }}
                    key={index}
                  >
                    <Typography variant="caption" color="textSecondary" style={{ marginTop: 4 }}>
                      {charCounts[index] || 0}/500
                    </Typography>
                    <TextField
                      label={formatMessage({ id: 'pages.notices.popup.edit.link.text' })}
                      error={link.value === ''}
                      id={`outlined-size-small-text-${index}`}
                      defaultValue={link.value}
                      inputProps={{ maxLength: 500 }}
                      variant="outlined"
                      size="small"
                      placeholder={formatMessage({
                        id: 'pages.notices.popup.edit.link.text.placeholder',
                      })}
                      onChange={(event) => {
                        const newValue = event.target.value

                        setCharCounts((prev) => ({
                          ...prev,
                          [index]: newValue.length,
                        }))

                        setLinksValue(
                          linksValue.map((value, index2) => {
                            if (index2 === index) {
                              return { key: value.key, value: newValue }
                            } else {
                              return value
                            }
                          })
                        )
                      }}
                      style={{ flex: 1 }}
                    />

                    <TextField
                      label={formatMessage({ id: 'pages.notices.popup.edit.links.url' })}
                      id={`outlined-size-small-url-${index}`}
                      defaultValue={link.key}
                      variant="outlined"
                      error={
                        !!errorMessages[index] ||
                        linksValue[index].key === '' ||
                        !linksValue[index].key.match(/^https:\/\/[^ "]+$/)
                      }
                      helperText={errorMessages[index] || ''}
                      inputProps={{ maxLength: 256 }}
                      size="small"
                      placeholder={formatMessage({
                        id: 'pages.notices.popup.edit.links.url.placeholder',
                      })}
                      onChange={(event) => {
                        const newValue = event.target.value

                        if (errorMessages[index]) {
                          setErrorMessages((prev) => ({
                            ...prev,
                            [index]: '',
                          }))
                        }

                        setLinksValue(
                          linksValue.map((value, index2) => {
                            if (index2 === index) {
                              return { key: newValue, value: value.value }
                            } else {
                              return value
                            }
                          })
                        )
                      }}
                      onPaste={(event) => {
                        const pastedText = event.clipboardData.getData('text')
                        if (pastedText.length > 256) {
                          event.preventDefault()
                          setErrorMessages((prev) => ({
                            ...prev,
                            [index]: formatMessage({
                              id: 'pages.notices.popup.edit.links.url.error',
                            }),
                          }))
                        }
                      }}
                      style={{ flex: 1 }}
                    />
                    <IconButton
                      style={{ color: '#E53A16', height: '30px' }}
                      aria-label={formatMessage({
                        id: 'pages.notices.popup.edit.links.url.delete',
                      })}
                      size="small"
                      onClick={() => {
                        setLinksValue(linksValue.filter((_, i) => i !== index))
                        setCharCounts((prev) => {
                          const newCounts = { ...prev }
                          delete newCounts[index]
                          return newCounts
                        })
                        setErrorMessages((prev) => {
                          const newErrors = { ...prev }
                          delete newErrors[index]
                          return newErrors
                        })
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            </div>

            <FormControl error={!profilesSelectedValue.length}>
              <InputLabel id="demo-mutiple-checkbox-label">
                {formatMessage({ id: 'pages.notices.popup.edit.target_users.title' })}
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={profilesSelectedValue}
                onChange={handleUsersChange}
                input={<Input />}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={getProfileText(value)} />
                    ))}
                  </Box>
                )}
              >
                {profilesList.map((profile) => (
                  <MenuItem key={profile.key} value={profile.key}>
                    <Checkbox checked={profilesSelectedValue.indexOf(profile.key) > -1} />
                    <ListItemText primary={profile.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showCCAA && (
              <FormControl error={!ccaaValue.length}>
                <InputLabel id="demo-mutiple-checkbox-label">
                  {formatMessage({ id: 'pages.notices.popup.edit.ccaa.title' })}
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={ccaaValue}
                  onChange={handleccaaChange}
                  input={<Input />}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={getCCAAText(value)} />
                      ))}
                    </Box>
                  )}
                >
                  {ccaaList.map((ccaa) => (
                    <MenuItem key={ccaa.key} value={ccaa.key}>
                      <Checkbox checked={ccaaValue.indexOf(ccaa.key) > -1} />
                      <ListItemText primary={ccaa.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <Grid container spacing={2} fullWidth>
                <Grid item xs={6}>
                  <DatePicker
                    error={!startDateValue}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={formatMessage({ id: 'pages.notices.popup.edit.start_date.title' })}
                    maxDate={endDateValue}
                    value={startDateValue}
                    onChange={(value) => {
                      setStartDateValue(getISOStringInitDate(value))
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    disableToolbar
                    error={!endDateValue}
                    variant="inline"
                    format="dd/MM/yyyy"
                    minDate={startDateValue}
                    margin="normal"
                    id="date-picker-inline"
                    label={formatMessage({ id: 'pages.notices.popup.edit.end_date.title' })}
                    value={endDateValue}
                    onChange={(value) => setEndDateValue(value)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <Autocomplete
              multiple
              freeSolo={tagsValue.length < 10}
              options={tagsListDropdown}
              getOptionDisabled={(options) => tagsValue.length >= 10}
              getOptionLabel={(option) => option}
              defaultValue={tagsValue}
              onChange={(event, newValue) => {
                setTagsValue(newValue)
              }}
              onBlur={(event) => {
                if (event.target.value !== '') {
                  if (!tagsValue.includes(event.target.value)) {
                    let tmp_tags = tagsValue
                    tmp_tags.push(event.target.value)
                    setTagsValue(tmp_tags)
                  }
                }
              }}
              clearOnBlur
              filterSelectedOptions
              style={{ marginTop: '20px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={formatMessage({ id: 'pages.notices.popup.edit.tags.title' })}
                  placeholder={
                    tagsValue.length < 10
                      ? formatMessage({ id: 'pages.notices.popup.edit.tags.placeholder.ok' })
                      : formatMessage({ id: 'pages.notices.popup.edit.tags.placeholder.ko' })
                  }
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
            }}
          >
            <Tooltip title={formatMessage({ id: 'global.delete' })}>
              <IconButton aria-label="delete" style={{ color: '#E53A16' }} onClick={deleteNotice}>
                <DeleteOutline />
              </IconButton>
            </Tooltip>
            <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={save}>
              {formatMessage({ id: 'global.save' })}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
)
